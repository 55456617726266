$(function () {
	APP.init();
	lozadInit();
	fancyboxInit();
	countUpInit();
	menuSpy();

	accordionCollapse();
	highlightUniqueLetters();
	toggleMenuCategory()
});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	mobileWrap = $(".mobile-wrap"),
	buttonSearch = $("header .button-search"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > 10
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 10);
		})
	},
	mapping: () => {
		let mainMenu = $("header .navbar-nav").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: "header .header-bot .header-bot-wrap",
			desktopMethod: "prependTo",
			breakpoint: 1199.98,
		});
		let menuTop = $("header .menu-top").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "appendTo",
			desktopWrapper: "header .header-top .button-search",
			desktopMethod: "insertAfter",
			breakpoint: 1199.98,
		});
		let contact = $("header .button-contact").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "appendTo",
			desktopWrapper: "header .header-right",
			desktopMethod: "prependTo",
			breakpoint: 575.98,
		});
	},
	toggleMobile: () => {
		buttonMenu.on('click', function () {
			mobileWrap.toggleClass('open');
			$('.menu-mobile-backdrop').toggleClass('open');
			body.toggleClass('overflow-hidden');
		});
		// Additional code to close the popup by clicking anywhere outside it
		$(document).on('click', function (e) {
			if (!$(e.target).closest(mobileWrap).length && !$(e.target).closest(buttonMenu).length) {
				mobileWrap.removeClass('open');
				$('.menu-mobile-backdrop').removeClass('open');
				body.removeClass('overflow-hidden');
			}
		});

		$('.mobile-wrap .menu-toggle').on('click', function () {
			$(this).toggleClass('active').next().slideToggle();
			$(this).parents('.has-mega-menu').siblings().find('.menu-toggle').removeClass('active').next().slideUp();
		});
	},
	toggleItem: () => {
		$(".toggle-wrap .toggle-item").click(function () {
			if ($(this).hasClass("is-toggle")) {
				$(this).find(".article").slideUp();
				$(this).removeClass("is-toggle");
			} else {
				$(this).find(".article").slideDown();
				$(this).addClass("is-toggle");
			}
		});
	},
	toggleSearch: () => {
		buttonSearch.on('click', function () {
			searchWrap.toggleClass('open');
			searchWrap.find('input').focus();
			$('.search-backdrop').toggleClass('open');
			body.toggleClass('overflow-hidden');
		});
		// Additional code to close the popup by clicking anywhere outside it
		$(document).on('click', function (e) {
			if (!$(e.target).closest(searchWrap).length && !$(e.target).closest(buttonSearch).length) {
				searchWrap.removeClass('open');
				$('.search-backdrop').removeClass('open');
				body.removeClass('overflow-hidden');
			}
		});
	},
	init: () => {
		APP.backToTop();
		APP.mapping();
		APP.toggleItem();
		APP.toggleMobile();
		APP.toggleSearch();
	}
}

var defaultSettingSwiper = {
	preventInteractionOnTransition: true,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
}

const homeBanner = new Swiper(".home-banner .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	...defaultSettingSwiper,
	navigation: {
		nextEl: ".home-banner .button-next",
		prevEl: ".home-banner .button-prev",
	},
	pagination: {
		el: ".home-banner .swiper-pagination",
		clickable: true,
	},
});

const bannerChild = new Swiper(".banner-child .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	...defaultSettingSwiper,
	navigation: {
		nextEl: ".banner-child .button-next",
		prevEl: ".banner-child .button-prev",
	},
	pagination: {
		el: ".banner-child .swiper-pagination",
		clickable: true,
	},
});
const ProfileSlider = new Swiper(".profile-slider .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
		1440: {
			slidesPerView: 4,
			spaceBetween: 80,
		},
	},
	speed: 1000,
	spaceBetween: 0,
	...defaultSettingSwiper,
	navigation: {
		nextEl: ".banner-child .button-next",
		prevEl: ".banner-child .button-prev",
	},
	pagination: {
		el: ".banner-child .swiper-pagination",
		clickable: true,
	},
});

$('.one-slider').each(function (index) {
	var isLoop = true;
	var isAutoplay = true;
	if($(this).hasClass('no-loop')){
		isLoop = false;
		isAutoplay = false;
	}
	$(this).find('.swiper').addClass(`swiper-one-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-one-${index}`);
	$(this).find('.button-prev').addClass(`prev-one-${index}`);
	$(this).find('.button-next').addClass(`next-one-${index}`);
	const oneSlider = new Swiper(`.swiper-one-${index}`, {
		...defaultSettingSwiper,
		loop: isLoop,
		autoplay: isAutoplay ? {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		} : false,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.one-slider .prev-one-${index}`,
			nextEl: `.one-slider .next-one-${index}`,
		},
		pagination: {
			el: `.one-slider .pagination-one-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			768: {
				spaceBetween: 32,
			},
		},
	});
});

$('.two-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-two-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-two-${index}`);
	$(this).find('.button-prev').addClass(`prev-two-${index}`);
	$(this).find('.button-next').addClass(`next-two-${index}`);
	const twoSlider = new Swiper(`.swiper-two-${index}`, {
		...defaultSettingSwiper,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.two-slider .prev-two-${index}`,
			nextEl: `.two-slider .next-two-${index}`,
		},
		pagination: {
			el: `.two-slider .pagination-two-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
		},
	});
});

$('.three-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-three-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-three-${index}`);
	$(this).find('.button-prev').addClass(`prev-three-${index}`);
	$(this).find('.button-next').addClass(`next-three-${index}`);
	const threeSlider = new Swiper(`.swiper-three-${index}`, {
		...defaultSettingSwiper,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.three-slider .prev-three-${index}`,
			nextEl: `.three-slider .next-three-${index}`,
		},
		pagination: {
			el: `.three-slider .pagination-three-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});
});

$('.four-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-four-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-four-${index}`);
	$(this).find('.button-prev').addClass(`prev-four-${index}`);
	$(this).find('.button-next').addClass(`next-four-${index}`);
	const fourSlider = new Swiper(`.swiper-four-${index}`, {
		...defaultSettingSwiper,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.four-slider .prev-four-${index}`,
			nextEl: `.four-slider .next-four-${index}`,
		},
		pagination: {
			el: `.four-slider .pagination-four-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
		},
	});
});

$('.six-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-six-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-six-${index}`);
	$(this).find('.button-prev').addClass(`prev-six-${index}`);
	$(this).find('.button-next').addClass(`next-six-${index}`);
	const sixSlider = new Swiper(`.swiper-six-${index}`, {
		...defaultSettingSwiper,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 2,
		navigation: {
			prevEl: `.six-slider .prev-six-${index}`,
			nextEl: `.six-slider .next-six-${index}`,
		},
		pagination: {
			el: `.six-slider .pagination-six-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			576: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 32,
			},
			1200: {
				slidesPerView: 6,
				spaceBetween: 32,
			},
		},
	});
});

$('.box-history').each(function (index) {
	$(this).find('.history-top .swiper').addClass(`history-top-${index}`);
	$(this).find('.history-bot .swiper').addClass(`history-bot-${index}`);
	$(this).find('.button-prev').addClass(`prev-history-${index}`);
	$(this).find('.button-next').addClass(`next-history-${index}`);
	let historyTopSlider = new Swiper(`.history-top-${index}`, {
		spaceBetween: 10,
		slidesPerView: 4,
		freeMode: true,
		watchSlidesProgress: true,
		breakpoints: {
			768: {
				slidesPerView: 5,
			},
			1024: {
				slidesPerView: 6,
			},
			1200: {
				slidesPerView: 7,
			},
		},
	});
	let historySlider = new Swiper(`.history-bot-${index}`, {
		spaceBetween: 10,
		navigation: {
			nextEl: `.box-history .next-history-${index}`,
			prevEl: `.box-history .prev-history-${index}`,
		},
		thumbs: {
			swiper: historyTopSlider,
		},
	});
});

$('.box-leader').each(function (index) {
	$(this).find('.leader-top .swiper').addClass(`leader-top-${index}`);
	$(this).find('.leader-bot .swiper').addClass(`leader-bot-${index}`);
	$(this).find('.leader-bot > .swiper-button > .button-prev').addClass(`prev-leader-${index}`);
	$(this).find('.leader-bot > .swiper-button > .button-next').addClass(`next-leader-${index}`);
	let leaderTopSlider = new Swiper(`.leader-top-${index}`, {
		spaceBetween: 10,
		slidesPerView: 4,
		freeMode: true,
		watchSlidesProgress: true,
		breakpoints: {
			768: {
				slidesPerView: 5,
			},
			1024: {
				slidesPerView: 6,
			},
			1200: {
				slidesPerView: 6,
			},
		},
	});
	let leaderSlider = new Swiper(`.leader-bot-${index}`, {
		spaceBetween: 10,
		allowTouchMove: false,
		// effect: "creative",
		// creativeEffect: {
		// 	prev: {
		// 		shadow: true,
		// 		translate: ["-20%", 0, -1],
		// 	},
		// 	next: {
		// 		translate: ["100%", 0, 0],
		// 	},
		// },
		navigation: {
			nextEl: `.box-leader .leader-bot > .swiper-button > .next-leader-${index}`,
			prevEl: `.box-leader .leader-bot > .swiper-button > .prev-leader-${index}`,
		},
		thumbs: {
			swiper: leaderTopSlider,
		},
	});
});

$('.leaders-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-leader-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-leader-${index}`);
	$(this).find('.button-prev').addClass(`prev-leader-${index}`);
	$(this).find('.button-next').addClass(`next-leader-${index}`);
	const leaderSlider = new Swiper(`.swiper-leader-${index}`, {
		...defaultSettingSwiper,
		// loop: true,
		// autoplay: {
		// 	delay: 5000,
		// 	disableOnInteraction: false,
		// 	pauseOnMouseEnter: true,
		// },
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.leaders-slider .prev-leader-${index}`,
			nextEl: `.leaders-slider .next-leader-${index}`,
		},
		pagination: {
			el: `.leaders-slider .pagination-leader-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
			1440: {
				slidesPerView: 4,
				spaceBetween: 98,
			},
		},
	});
});

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function countUpInit() {
	$('.count-up').each(function (index) {
		$(this).attr('id', `countUp-${index}`)
		const endVal = $(this).data('count');

		const options = {
			separator: '.',
			enableScrollSpy: true,
			scrollSpyDelay: 1000,
			scrollSpyOnce: true,
		};

		const countUp = new CountUp(`countUp-${index}`, endVal, options);

		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}

		countUp.handleScroll();
	});
}

function menuSpy() {
	var elm = document.querySelector('#menuSpy');
	var ms = new MenuSpy(elm, {
		activeClass: 'active',
		threshold: 120,
	});
}

function accordionCollapse() {
	$('.accordion-item > .accordion-title').on('click', function () {
		$(this).toggleClass('active');
		$(this).next().slideToggle();
		$(this).parent().siblings().find(' > .accordion-title').removeClass('active').next().slideUp();
	});
}

function highlightUniqueLetters() {
	$('.university-name').each(function () {
		var text = $(this).text().split(' '); // Split text into words
		var uniqueLetters = {};
		var textWithSpans = '';
		text.forEach(function (word) {
			var firstLetter = word[0];
			if (!uniqueLetters[firstLetter]) {
				uniqueLetters[firstLetter] = true;
				textWithSpans += '<span class="highlight">' + firstLetter + '</span>' + word.slice(1) + ' '; // Append the rest of the word after the highlighted first letter
			} else {
				textWithSpans += word + ' ';
			}
		});
		$(this).html(textWithSpans.trim()); // Remove trailing space
	});
}

function toggleMenuCategory(){
	$('.box-categories .category-toggle').on('click', function(){
		$(this).parents('.category-title').next().slideToggle();
		$(this).parents('li').toggleClass('active').siblings().removeClass('active').find('.sub-category').slideUp();
	})
}
